<template>

        <v-card class="text-left">
            <v-card-title>
                <div class="subtitle-1 font-weight-light text-left ml-3 mt-2"> Ajout d'une agence</div>
                <v-spacer />
                <v-icon aria-label="Close" @click="handleClose()" >
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <el-divider></el-divider>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.nom" label="Nom"/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.adresse" label="Adresse"/>
                    </v-col>
                    <v-col cols="12" md="4">
                        <el-select v-model="form.ville" placeholder="Ville" class="mt-2">
                            <el-option v-for="item in villes" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <el-select v-model="form.responsable_id" placeholder="Rèsponsable" class="mt-2">
                            <el-option v-for="item in all_user" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <el-select v-model="form.banques" multiple placeholder="banques" class="mt-2">
                            <el-option v-for="item in all_banque" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-row>
                    <v-col cols="12" class="text-right" >
                        <el-button type="warning" round  @click="handleClose()">Annuler</el-button>
                        <el-button type="primary" round @click="handleSave()" >Enregistrer</el-button>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    export default {

        components: {
        },

        props: ['handleClose','loadData'],

        data() {
            return {
                search: '',
                loading: true,
                form: {
                    id  : null,
                    code  : "",
                    nom : "",
                    adresse : "",
                    ville : "",
                    quartier : "",
                    responsable_id : null,
                    is_active : 1,
                    banques : "",
                },

                form_empty: {
                    id  : "",
                    code  : "",
                    nom : "",
                    adresse : "",
                    ville : "",
                    quartier : "",
                    responsable_id : "",
                    is_active : "",
                    banques : "",
                },
                
                all_user: [],
                all_banque: [],

                villes: [
                    { value: 'Yaoundé', label: 'Yaoundé'},
                    { value: 'Douala', label: 'Douala'},
                    { value: 'Akonolinga', label: 'Akonolinga'},
                    { value: 'Abong Mbang', label: 'Abong Mbang'},
                    { value: 'Bali', label: 'Bali'},
                    { value: 'Banyo', label: 'Banyo'},
                    { value: 'Bangangté', label: 'Bangangté'},
                    { value: 'Batouri', label: 'Batouri'},
                    { value: 'Bélabo', label: 'Bélabo'},
                    { value: 'Bertoua', label: 'Bertoua'},
                    { value: 'Bafang', label: 'Bafang'},
                    { value: 'Buéa', label: 'Buéa'},
                    { value: 'Bafia', label: 'Bafia'},
                    { value: 'Bamenda', label: 'Bamenda'},
                    { value: 'Bafoussam', label: 'Bafoussam'},
                    { value: 'Dschang', label: 'Dschang'},
                    { value: 'Edéa', label: 'Edéa'},
                    { value: 'Ebolowa', label: 'Ebolowa'},
                    { value: 'Foumban', label: 'Foumban'},
                    { value: 'Nkongsamba', label: 'Nkongsamba'},
                    { value: 'Loum', label: 'Loum'},
                    { value: 'Garoua', label: 'Garoua'},
                    { value: 'Guider', label: 'Guider'},
                    { value: 'Ngaoundéré', label: 'Ngaoundéré'},
                    { value: 'Kousséri', label: 'Kousséri'},
                    { value: 'kaélé', label: 'kaélé'},
                    { value: 'Kumba', label: 'Kumba'},
                    { value: 'Mbouda', label: 'Mbouda'},
                    { value: 'Maroua', label: 'Maroua'},
                    { value: 'Mbalmayo', label: 'Mbalmayo'},
                    { value: 'Mokolo', label: 'Mokolo'},
                    { value: 'Meiganga', label: 'Meiganga'},
                    { value: 'Mbandjock', label: 'Mbandjock'},
                    { value: 'Nanga Eboko', label: 'Nanga Eboko'},
                    { value: 'Mamfé', label: 'Mamfé'},
                    { value: 'Melong', label: 'Melong'},
                    { value: 'Mbanga', label: 'Mbanga'},
                    { value: 'Nkambe', label: 'Nkambe'},
                    { value: 'Manjo', label: 'Manjo'},
                    { value: 'Mora', label: 'Mora'},
                    { value: 'Wum', label: 'Wum'},
                    { value: 'Yagoua', label: 'Yagoua'},
                    { value: 'Foumbot', label: 'Foumbot'},
                    { value: 'Limbé', label: 'Limbé'},
                    { value: 'Kribi', label: 'Kribi'},
                    { value: 'Kumbo', label: 'Kumbo'},
                    { value: 'Obala', label: 'Obala'},
                    { value: 'Sangmélima', label: 'Sangmélima'},
                    { value: 'Tibati', label: 'Tibati'},
                    { value: 'Tiko', label: 'Tiko'},
                    { value: 'Yokadouma', label: 'Yokadouma'},

                ],
            }
        },

        created(){
            this.loadUser();
            this.loadBanque();
        },

        methods: {

            async loadUser()
            {
                NProgress.start();
                try {
                
                    let response = await axios.get('xperf/AcceuilColab');
                    response = response.data.data_T_colab;

                    response.forEach(element => {

                        let user = {
                            "label": element.name,
                            "value": element.id
                        }

                        this.all_user.push(user);
                    });

                } 
                catch (error) {
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();
            },

            async loadBanque()
            {
                NProgress.start();
                try {
                
                    let response = await axios.get('v1/xperf/setting/banque/get');
                    response = response.data;

                    response.forEach(element => {

                        let banque = {
                            "label": element.title,
                            "value": element.id
                        }

                        this.all_banque.push(banque);
                    });

                } 
                catch (error) {
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();
            },

            async handleSave() {
                
                NProgress.start();

                try {

                    console.log(this.form);
                    this.form.code = this.form.nom;
                    
                    const response = await axios.post('v1/xperf/setting/agence/create', this.form);
                    this.handleClose();
                    this.loadData();
                    
                    this.form = '';
                    this.form = this.form_empty;

                    this.$notify({
                            type: 'success',
                            title: "Enregistrement",
                            message: "Utilisateur enregistrer avec succès"
                    });
                } 
                catch (error) {
                    this.handleClose();
                    this.$notify({
                        type: 'error',
                        title: "Enregistrement",
                        message: "Echec d'enregistrement"
                    });
                }

                NProgress.done();

            },
        },
    }
</script>

<style>
    
    element.style {
        overflow:hiden;
    }
</style>